<template>
  <a-row :gutter="6" class="panel-group">
    <a-col :xs="8" :sm="8" :lg="3" class="card-panel-col"  v-for="(item ,i)  in accountInfo" :key="i">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper">
          {{item.name}}
        </div>
        <div class="card-panel-description">
          <count-to :start-val="0" :end-val="item.value" :duration="5" class="card-panel-num" />
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  components: {
    CountTo
  },
  props: {
    accountInfo: {
      type: Array,
      default: []
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.panel-group {
  margin-top: 10px;

  .card-panel-col {
    margin-bottom: 15px;

  }

  .card-panel {
    cursor: pointer;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    border-radius:6px;
    &:hover {
      .card-panel-icon-wrapper {
        color: #36a3f7;
      }
    }



    .card-panel-icon-wrapper {
      float: left;
      margin: 10px 0 0 10px;
      padding: 14px;
      font-weight: 500;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;
      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
